























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class InterestsGallery extends Vue {
    pictures = [
        'https://imgur.com/syJZdia.png',
        'https://imgur.com/AVik7qa.png',
        'https://imgur.com/EsZeqmk.png',
        'https://imgur.com/MrgKIiB.png',
        'https://imgur.com/RgwoPma.png',
        'https://imgur.com/Uc9PQxt.png',
        'https://imgur.com/X6mpD1S.png',
        'https://imgur.com/zL4OvyT.png',
    ]
}
