












import { Component, Vue } from 'vue-property-decorator';

import HeroAlt from '@/components/sections/HeroAlt.vue';
import InfoAlt from '@/components/sections/InfoAlt.vue';
import InterestsGallery from '@/components/sections/InterestsGallery.vue';
import ImageAndText from '@/components/sections/ImageAndText.vue';

@Component({
    components: {
        HeroAlt,
        InfoAlt,
        ImageAndText,
        InterestsGallery,
    },
    metaInfo: () => ({
        title: 'Interests & Lifestyle'
    })
})
export default class Interests extends Vue {
    MyInterests = [
        { indented: true, text: 'As a father, my interests are mostly about my family. I could never spend too much time with my family. Everywhere I go, I’m always with my family. Anything I do, my family is always there. I also love to travel, and when I go on a trip, my sons would keep me company throughout it. Occasionally, I would be invited to speak at in front of hundreds of students about my struggles and experiences, and it is heart-warming to see my family there to support me and cheer me on. I can’t imagine how different my life would’ve been if I didn’t have the encouragement and love of my family. However, I’m glad I have a loving family because I get to enjoy things and try new ideas together. Everything I love to do all comes from my family.'},
    ]
}
