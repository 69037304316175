




















































import { Component, Prop, Vue } from 'vue-property-decorator';

export interface Body {
  [key: number]: Paragraph;
}

export interface Paragraph {
  indented: boolean;
  text: string;
}

@Component
export default class ImageAndText extends Vue {
    @Prop(String) readonly title!: string
    @Prop(String) readonly image!: string
    @Prop({default: false, type: Boolean, required: false}) readonly reversed!: boolean
    @Prop({default: false, type: Boolean, required: false}) readonly indented!: boolean
    @Prop(Array) readonly body!: Body

}
